<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Add Cinema</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="title">
                    Title
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    required
                    v-model="cinema.CinemaName"
                    id="id"
                    placeholder="Cinema Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="country">
                    Country
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.Country" id="country" placeholder="Country"></b-form-input> -->
                  <b-form-select
                    required
                    @change.native="getStatesList($event.target.value)"
                    v-model="cinema.CountryID"
                    id="month1"
                    :plain="true"
                    value-field="CountryID"
                    text-field="CountryName"
                    :options="countries"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Country --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="state">
                    State
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.State" id="state" placeholder="State"></b-form-input> -->
                  <b-form-select
                    required
                    @change.native="getCityList($event.target.value)"
                    v-model="cinema.StateID"
                    id="month1"
                    :plain="true"
                    value-field="StateID"
                    text-field="StateName"
                    :options="states"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select State --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="city">
                    City
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.City" id="city" placeholder="City"></b-form-input> -->
                  <b-form-select
                    required
                    v-model="cinema.CityID"
                    id="month1"
                    :plain="true"
                    value-field="CityID"
                    text-field="CityName"
                    :options="cities"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select City --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="email">Email</label>
                  <b-form-input type="text" v-model="cinema.Email" id="email" placeholder="Email"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="phone">Phone</label>
                  <b-form-input
                    type="number"
                    step="any"
                    v-model="cinema.Phone"
                    id="phone"
                    placeholder="Phone"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="location">Location</label>
                  <b-form-input
                    type="text"
                    v-model="cinema.Location"
                    id="location"
                    placeholder="Location"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="pin_code">Pin Code</label>
                  <b-form-input
                    type="number"
                    step="any"
                    v-model="cinema.Pincode"
                    id="pin-code"
                    placeholder="Pin Code"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="address">Address</label>
                  <b-form-input
                    type="text"
                    v-model="cinema.Address"
                    id="address"
                    placeholder="Address"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="organization">
                    Organization
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.Organization" id="organization" placeholder="Organization"></b-form-input> -->
                  <b-form-select
                    required
                    v-model="cinema.OrganizationID"
                    id="organization"
                    :plain="true"
                    value-field="OrganizationID"
                    text-field="OrganizationName"
                    :options="organizations"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Organization --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="latitude">
                    Latitude
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="number"
                    step="any"
                    v-model="cinema.Latitude"
                    id="latitude"
                    placeholder="Latitude"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <label for="longitude">
                    Longitude
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="number"
                    step="any"
                    v-model="cinema.Longitude"
                    id="longitude"
                    placeholder="Longitude"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="data_provider">Data Provider</label>
                  <b-form-input
                    type="number"
                    step="any"
                    v-model="cinema.DataProviderID"
                    id="data-provider"
                    placeholder="Data Provider"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <label for="cinema_id">Data Provider Cinema ID</label>
                  <b-form-input
                    type="number"
                    step="any"
                    v-model="cinema.DataProviderCinemaID"
                    id="plot"
                    placeholder="Data Provider Cinema ID"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="booking_cut_off_time">
                    Booking cut off time (Minutes)
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    required
                    type="number"
                    step="any"
                    v-model="cinema.Booking_CutoffTime"
                    id="booking-cut-off-time"
                    placeholder="Booking cut off time (Minutes)"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="max_no_of_seats">
                    Booking Max No of Seats
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    required
                    type="number"
                    step="any"
                    v-model="cinema.Booking_Max_No_Seats"
                    id="max_no_of_seats"
                    placeholder="Booking Max No of Seats"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="no_of_schedules">
                    No of Schedules
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    required
                    type="number"
                    step="any"
                    v-model="cinema.No_of_Schedules"
                    id="no-of-schedules"
                    placeholder="Number of Schedules"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="expiry">Expiry</label>
                  <b-form-select
                    v-model="cinema.SCExpiry"
                    id="expiry"
                    :plain="true"
                    :options="expiries"
                  >
                    <template slot="first">
                      <option :value="''" disabled>-- Select Expiry --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="no_of_schedules">
                    Website
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    v-model="cinema.Website"
                    id="Website"
                    placeholder="Website"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="Screen_formate_screentype">
                    Screen types
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    v-model="cinema.Screen_formate_screentype"
                    id="Screen_formate_screentype"
                    placeholder="Screen Types"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <!-- cinema_Info -->
              <b-col sm="3">
                <b-form-group>
                  <label for="cinema_Info">
                    Cinema Info
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    v-model="cinema.cinema_Info"
                    id="cinema_Info"
                    placeholder="Cinema Info"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <!-- fnb   -->
              <b-col sm="3">
                <b-form-group>
                  <label for="fandb_avli">
                    FnB Available
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    v-model="cinema.fandb_avli"
                    id="fandb_avli"
                    placeholder="FnB Available"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="thumbnail_image">
                    App Image (266 x 368)
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-file
                    :disabled="cinema.CinemaName == '' ? true : false"
                    :required="cinema.CinemaID == null ? true : false"
                    v-on:change="uploadImageHandler($event.target, 'ThumbnailImage',{ h:368,w:266}, 0)"
                    accept="image/jpeg, image/png, image/gif"
                    ref="ThumbnailImagefileinput"
                    id="fileInput"
                    :plain="true"
                  ></b-form-file>
                  <!-- v-on:change="uploadThumbnail($event.target)" -->
                  <input type="hidden" v-model="cinema.ThumbnailImage" />
                </b-form-group>
              </b-col>
              <b-col sm="3" id="preview">
                <img
                  v-if="cinema.ThumbnailImage != ''"
                  :src="BaseURL+cinema.ThumbnailImage"
                  class="img-fluid"
                />
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="thumbnail_image">
                    Header Image (418 x 251)
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-file
                    :disabled="cinema.CinemaName == '' ? true : false"
                    :required="cinema.CinemaID == null ? true : false"
                    v-on:change="uploadImageHandler($event.target, 'HeaderImage',{ h:251,w:418}, 0)"
                    accept="image/jpeg, image/png, image/gif"
                    ref="HeaderImagefileinput"
                    id="fileInput"
                    :plain="true"
                  ></b-form-file>
                  <input type="hidden" v-model="cinema.HeaderImage" />
                </b-form-group>
              </b-col>
              <b-col sm="3" id="HeaderImage">
                <img
                  v-if="cinema.HeaderImage != ''"
                  :src="BaseURL+cinema.HeaderImage"
                  class="img-fluid"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="thumbnail_image">
                    Web Image (1680 × 370)
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-file
                    :disabled="cinema.CinemaName == '' ? true : false"
                    :required="cinema.CinemaID == null ? true : false"
                    v-on:change="uploadImageHandler($event.target,'HeaderWebImage',{ w:1680,h:370},1)"
                    accept="image/jpeg, image/png, image/gif"
                    ref="HeaderWebImagefileinput"
                    id="fileInput"
                    :plain="true"
                  ></b-form-file>
                  <input type="hidden" v-model="cinema.HeaderWebImage" />
                </b-form-group>
              </b-col>
              <b-col sm="3" id="HeaderWebImage">
                <img
                  v-if="cinema.HeaderWebImage != ''"
                  :src="BaseURL+cinema.HeaderWebImage"
                  class="img-fluid"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="status">Status</label>
                  <br />
                  <c-switch
                    class="mx-1"
                    color="primary"
                    v-model="cinema.CinemaIsActive"
                    defaultChecked
                    variant="3d"
                    label
                    v-bind="labelIcon"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ commontext.submitButton }}
                </b-button>
              </b-col>

              <b-col sm="6">
                <b-button @click="resetForm" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import MovieService from "@/services/MovieService";
import CinemaService from "@/services/CinemaService";
import MasterService from "@/services/MasterService";
import UploadService from "@/services/UploadService";

import OrganizationService from "@/services/OrganizationService";

export default {
  name: "AddCinema",
  components: {
    cSwitch
  },
  data() {
    return {
      BaseURL: "",
      commontext: {
        submitButton: "Submit"
      },
      cinemaObject: {
        website: "",
        images: {
          headerImage: "",
          webImage: "",
          appImage: ""
        }
      },
      cinema: {
        Flag: 1,
        Website: "",
        CinemaName: "",
        ThumbnailImage: "",
        HeaderWebImage: "",
        HeaderImage: "",
        Address: "",
        OrganizationID: 0,
        Phone: "",
        Email: "",
        Location: "",
        CountryID: 0,
        StateID: 0,
        DataProviderID: "",
        DataProviderCinemaID: "",
        CinemaID: null,
        CityID: 0,
        Pincode: "",
        Latitude: "",
        Longitude: "",
        No_of_Schedules: "",
        Booking_CutoffTime: "",
        Booking_Max_No_Seats: "",
        SCExpiry: "",
        Screen_formate_screentype: "",
        cinema_Info: "",
        fandb_avli: "",
        CinemaIsActive: true
      },
      expiries: [],
      expiryHit: false,
      Expiry: "",
      countries: [],
      states: [],
      cities: [],
      organizations: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getOrganization();
    this.getCountries();
    this.getExpiry();
    const { cinemaID } = this.$route.query;
    if (cinemaID) {
      this.getCinemaListById(cinemaID);
    }
  },
  methods: {
    getCinemaListById: function(CinemaID) {
      let payload = {
        CinemaID
      };
      CinemaService.getCinemaByID(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status && data.CinemaList.length > 0) {
            this.commontext.submitButton = "Update";

            this.getStatesList(data.CinemaList[0].CountryID);
            this.getCityList(data.CinemaList[0].StateID);
            this.cinema = data.CinemaList[0];
            this.cinema.Flag = 2;
            this.BaseURL = data.CinemaList[0].BaseURL;

            // let previewDiv = document.getElementById("preview");
            // while (previewDiv.firstChild) {
            //   previewDiv.removeChild(previewDiv.firstChild);
            // }
            // var previewImg = document.createElement("img");
            // previewDiv.appendChild(previewImg);
            // previewImg.src =
            //   data.CinemaList[0].BaseURL + data.CinemaList[0].ThumbnailImage;
            // previewImg.classList.add("img-fluid");

            // //
            // let previewDivHeaderImage = document.getElementById("HeaderImage");
            // while (previewDivHeaderImage.firstChild) {
            //   previewDivHeaderImage.removeChild(
            //     previewDivHeaderImage.firstChild
            //   );
            // }
            // var previewOne = document.createElement("img");
            // previewDivHeaderImage.appendChild(previewOne);
            // previewOne.src =
            //   data.CinemaList[0].BaseURL + data.CinemaList[0].ThumbnailImage;
            // previewOne.classList.add("img-fluid");

            // //
            // let previewDivHeaderWebImage = document.getElementById(
            //   "HeaderWebImage"
            // );
            // while (previewDivHeaderWebImage.firstChild) {
            //   previewDivHeaderWebImage.removeChild(
            //     previewDivHeaderWebImage.firstChild
            //   );
            // }
            // var previewTwo = document.createElement("img");
            // previewDivHeaderWebImage.appendChild(previewTwo);
            // previewTwo.src =
            //   data.CinemaList[0].BaseURL + data.CinemaList[0].ThumbnailImage;
            // previewTwo.classList.add("img-fluid");
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.commontext.submitButton = "Submit";
      this.cinema = {
        Flag: 1,
        Website: "",
        CinemaName: "",
        HeaderWebImage: "",
        HeaderImage: "",
        ThumbnailImage: "",
        Address: "",
        OrganizationID: 0,
        Phone: "",
        Email: "",
        Location: "",
        CountryID: 0,
        StateID: 0,
        DataProviderID: "",
        DataProviderCinemaID: "",
        CinemaID: null,
        CityID: 0,
        Pincode: "",
        Latitude: "",
        Longitude: "",
        No_of_Schedules: "",
        Booking_CutoffTime: "",
        Booking_Max_No_Seats: "",
        Screen_formate_screentype: "",
        cinema_Info: "",
        fandb_avli: "",
        CinemaIsActive: true
      };
      this.BaseURL = "";
      // this.$refs.thumbnail_imagefileinput.reset();
      this.$refs.HeaderImagefileinput.reset();
      this.$refs.HeaderWebImagefileinput.reset();

      // let previewDiv = document.getElementById("preview");
      // while (previewDiv.firstChild) {
      //   previewDiv.removeChild(previewDiv.firstChild);
      // }
      // let previewDivOne = document.getElementById("HeaderImage");
      // while (previewDivOne.firstChild) {
      //   previewDivOne.removeChild(previewDivOne.firstChild);
      // }
      // let previewDivTwo = document.getElementById("HeaderWebImage");
      // while (previewDivTwo.firstChild) {
      //   previewDivTwo.removeChild(previewDivTwo.firstChild);
      // }
      window.location.replace(window.location.href.split("?")[0]);
    },
    getExpiry: function() {
      CinemaService.getExpiry()
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.expiries = data.CinemaExpiryList;
            this.expiryHit = true;
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getOrganization: function() {
      this.organizations = [];
      OrganizationService.getOrganizationDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organizations = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCountries: function() {
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getStatesList: function(CountryID) {
      let payload = {
        CountryID
      };
      this.states = [];
      MasterService.getStatesDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.StateList.length > 0) {
            this.states = data.StateList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCityList: function(StateID) {
      this.cities = [];
      let payload = {
        StateID
      };
      MasterService.getCitiesDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CityList.length > 0) {
            this.cities = data.CityList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    searchMovie: function() {
      MovieService.search(this.search)
        .then(response => {
          const { data } = response;
          if (data.Search.length > 0) {
            this.movies = res.data.Search;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    selectMovie: function() {
      let title = this.movies[this.movieSelected].Title;

      MovieService.searchSingleMovie(title)
        .then(response => {
          const { data } = response;
          if (data) {
            this.movie = data;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    uploadImageHandler: function(image, name, size, index) {
      let self = this;
      var _URL = window.URL || window.webkitURL;
      var file, img;
      if ((file = image.files[0])) {
        img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = async function() {
          if (img.height != size.h || img.width != size.w) {
            // if (false) {
            self.$refs[name + "fileinput"].reset();
            self.showMessageSnackbar(
              `Please upload a ${size.h}x${size.w} image`
            );
          } else {
            var formdata = new FormData();
            var file = image.files[0];
            formdata.append("assets/images/cinemas", image.files[0]);
            formdata.append("rename", self.cinema.CinemaName);
            UploadService.uploadFiles(formdata)
              .then(response => {
                const { data } = response;
                data.Message ? self.showMessageSnackbar(data.Message) : "";
                if (data && data.Status) {
                  self.cinema[name] = data.Path;
                  self.BaseURL = data.BaseURL;
                }
              })
              .catch(error => {
                self.showMessageSnackbar(
                  "Oops! Something went wrong. kindly contact your administrator"
                );
              });
          }
        };
      }
    },
    uploadThumbnail: function(image) {
      let cinemas = this.cinema;
      let self = this;
      let _URL = window.URL || window.webkitURL;
      let file, img;
      if ((file = image.files[0])) {
        img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = async function() {
          if (img.height != 368 || img.width != 266) {
            // if (false) {
            self.$refs.thumbnail_imagefileinput.reset();
            self.showMessageSnackbar("Please upload a 266x368 image");
          } else {
            var formdata = new FormData();
            formdata.append("assets/images/cinemas", image.files[0]);
            formdata.append("rename", self.cinema.CinemaName);
            UploadService.uploadFiles(formdata)
              .then(response => {
                const { data } = response;
                self.showMessageSnackbar(
                  data.Message || "Oops! Something went wrong."
                );
                if (data.Status) {
                  // cinemas.ThumbnailImage = data.Path;
                  self.cinema.ThumbnailImage = data.Path;
                  self.BaseURL = data.BaseURL;
                }
              })
              .catch(error => {
                self.showMessageSnackbar(
                  "Oops! Something went wrong. kindly contact your administrator"
                );
              });
          }
        };
      }
    },
    // uploadThumbnail: function(image) {
    //   // let that = this;
    //   let cinemas = this.cinema;
    //   var _URL = window.URL || window.webkitURL;
    //   let self = this;
    //   var file, img;
    //   if ((file = image.files[0])) {
    //     img = new Image();
    //     img.onload = function() {
    //       // if (img.height != 368 || img.width != 266) {
    //       if (false) {
    //         self.$refs.thumbnail_imagefileinput.reset();
    //         self.showMessageSnackbar("Please upload a 266x368 image");
    //       } else {
    //         var formdata = new FormData();
    //         var file = image.files[0];
    //         formdata.append("assets/images/cinemas", image.files[0]);
    //         formdata.append("rename", self.cinema.CinemaName);
    //         // let res = await CinemaService.uploadFiles(formdata);
    //         UploadService.uploadFiles(formdata)
    //           .then(response => {
    //             const { data } = response;
    //             self.showMessageSnackbar(
    //               data.Message || "Oops! Something went wrong."
    //             );
    //             if (data.Status) {
    //               // cinemas.ThumbnailImage = data.Path;
    //               self.cinema.ThumbnailImage = data.Path;
    //               var previewDiv = document.getElementById("preview");
    //               var previewImg = "";
    //               var previewImg = document.createElement("img");
    //               while (previewDiv.firstChild) {
    //                 previewDiv.removeChild(previewDiv.firstChild);
    //               }
    //               previewDiv.appendChild(previewImg);
    //               previewImg.src = window.URL.createObjectURL(image.files[0]);
    //               previewImg.classList.add("img-fluid");
    //             }
    //           })
    //           .catch(error => {
    //             self.showMessageSnackbar(
    //               "Oops! Something went wrong. kindly contact your administrator"
    //             );
    //           });
    //       }
    //     };
    //     // self.cinema.ThumbnailImage = cinemas.ThumbnailImage;
    //     img.src = _URL.createObjectURL(file);
    //   }
    // },
    validation: function() {
      let emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      let phoneReg = /\d{7}/;

      if (this.cinema.CinemaName == "") {
        this.showMessageSnackbar("Cinema name cannot be empty.");
        return false;
      } else if (this.cinema.CountryID == 0) {
        this.showMessageSnackbar("Please Select the Country.");
        return false;
      } else if (this.cinema.StateID == 0) {
        this.showMessageSnackbar("Please Select the State");
        return false;
      } else if (this.cinema.CityID == 0) {
        this.showMessageSnackbar("Please Select a City");
        return false;
      } else if (!emailReg.test(this.cinema.Email)) {
        this.showMessageSnackbar("Email address must be unique and valid.");
        return false;
      } else if (!phoneReg.test(this.cinema.Phone)) {
        this.showMessageSnackbar("Phone number must be at least 10 digits.");
        return false;
      } else if (this.cinema.Location == "") {
        this.showMessageSnackbar("Location Cannot be empty.");
        return false;
      } else if (this.cinema.Pincode == "") {
        this.showMessageSnackbar("Pincode Cannot be empty.");
        return false;
      } else if (this.cinema.Address == "") {
        this.showMessageSnackbar("Address Cannot be empty.");
        return false;
      } else if (this.cinema.OrganizationID == 0) {
        this.showMessageSnackbar("Please select the Organization.");
        return false;
        // } else if (this.cinema.Latitude <= 0) {
        // } else if (String(this.cinema.Latitude).length == "") {
      } else if (String(this.cinema.Latitude).length == 0) {
        this.showMessageSnackbar("Latitude Cannot be empty.");
        return false;
        // } else if (String(this.cinema.Longitude).length == "") {
      } else if (String(this.cinema.Longitude).length == 0) {
        // } else if (this.cinema.Longitude <= 0) {
        this.showMessageSnackbar("Longitude Cannot be empty.");
        return false;
      } else if (this.cinema.DataProviderID == "") {
        this.showMessageSnackbar("Data Provider ID Cannot be empty.");
        return false;
      } else if (this.cinema.DataProviderCinemaID == "") {
        this.showMessageSnackbar("Cinema Data Provider ID Cannot be empty.");
        return false;
      } else if (this.cinema.Booking_CutoffTime == "") {
        this.showMessageSnackbar("Booking Cut Off Time Cannot be empty.");
        return false;
      } else if (this.cinema.Booking_Max_No_Seats == "") {
        this.showMessageSnackbar("Booking Max No Seats Cannot be empty.");
        return false;
      } else if (this.cinema.No_of_Schedules == "") {
        this.showMessageSnackbar("No of Schedules Cannot be empty.");
        return false;
      } else if (this.cinema.SCExpiry == "") {
        this.showMessageSnackbar("Expiry Cannot be empty.");
        return false;
      }
      // else if (this.cinema.Website == "") {
      //   this.showMessageSnackbar("Cinema Website Cannot be empty.");
      //   return false;
      // }
      else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      let self = this;
      CinemaService.addCinema(this.cinema)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            self.resetForm();
            self.$router.push({
              path: "/org-management/cinemas/all-cinemas"
            });
          }
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          console.log("Catch on AddCinema-OnSubmit Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  },
  computed: {
    // calculateExpiry() {
    //   if (
    //     this.cinema.Website != "" &&
    //     this.cinema.CinemaName != "" &&
    //     this.cinema.ThumbnailImage != "" &&
    //     this.cinema.HeaderWebImage != "" &&
    //     this.cinema.HeaderImage != "" &&
    //     this.cinema.Address != "" &&
    //     this.cinema.OrganizationID != 0 &&
    //     this.cinema.Phone != "" &&
    //     this.cinema.Email != "" &&
    //     this.cinema.Location != "" &&
    //     this.cinema.CountryID != 0 &&
    //     this.cinema.StateID != 0 &&
    //     this.cinema.DataProviderID != "" &&
    //     this.cinema.DataProviderCinemaID != "" &&
    //     this.cinema.CityID != 0 &&
    //     this.cinema.Pincode != "" &&
    //     this.cinema.Latitude != "" &&
    //     this.cinema.Longitude != "" &&
    //     this.cinema.No_of_Schedules != "" &&
    //     this.cinema.Booking_CutoffTime != "" &&
    //     this.cinema.Booking_Max_No_Seats != ""
    //   ) {
    //     this.getExpiry();
    //     return this.expiries;
    //   } else {
    //     return [];
    //   }
    // }
  }
  // watch: {
  // }
};
</script>
<style>
</style>
